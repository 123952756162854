html
{
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body
{
	font-family: "微軟正黑體","Helvetica Neue", Helvetica, Arial, sans-serif !important;
	margin: 0;
}
h3
{
	margin: 40px 0 0;
}

ul#itemList,
.sidebar-panel-nav,
.menu_subItem
{
	list-style-type: none;
	padding: 0;
}

a
{
	cursor: pointer;
}

a,
a:hover,
a:active
{
	text-decoration: none !important;
}

input
{
	margin: 0;
}

/* Hide arrows for input type: number ============================= */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
{
	-webkit-appearance: none;
}

/* Firefox */
input[type=number]
{
	-moz-appearance: textfield;
}

input.inputBgHidden
{
	background: #fff;
}
/* ============================= */

@media (max-width: 576px)
{
	input,
	select,
	textarea
	{
		font-size: 16px !important;
	}
}

.pull-right
{
	float: right !important;
}

.inline
{
	display: inline-block !important;
}

.block
{
	display: block !important;
}

.space-2
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 2px 0 1px;
}

.space-4
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 4px 0 3px;
}

/* scrollBar ============================= */
/* Firefox */
*
{
	scrollbar-width: thin;
}

/* Chrome, Safari, Edge, Opera */
@media screen and (min-width: 768px)
{
	::-webkit-scrollbar
	{
		width: 10px;
		height: 10px;
	}

	::-webkit-scrollbar-track
	{
		background-color: rgba(100, 100, 100, 0.1);
	}

	::-webkit-scrollbar-thumb
	{
		background-color: #888;
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:hover
	{
		background: #777;
	}
}
/* ============================= */

.bigger-100
{
	font-size: 100% !important;
}

.bigger-110
{
	font-size: 110% !important;
}

.bigger-120
{
	font-size: 120% !important;
}

.bigger-130
{

	font-size: 130% !important;
}

.bigger-140
{
	font-size: 140% !important;
}

.bigger-150
{
	font-size: 150% !important;
}

.bigger-160
{
	font-size: 160% !important;
}

.bigger-170
{
	font-size: 170% !important;
}

.bigger-180
{
	font-size: 180% !important;
}

.bigger-190
{
	font-size: 190% !important;
}

.bigger-200
{
	font-size: 200% !important;
}

.space-10
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 10px 0 9px;
}

[class*="vspace-10"]
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 10px 0 9px 0;
}

.space-8
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 8px 0 7px;
}

[class*="vspace-8"]
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 8px 0 7px 0;
}

.space-6
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 6px 0 5px;
}

[class*="vspace-6"]
{
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0;
	margin: 6px 0 5px 0;
}

/* Datatable */
div.DTS
{
	display: block !important;
}

div.DTS tbody th,
div.DTS tbody td
{
	white-space: nowrap;
}

div.DTS div.DTS_Loading
{
	z-index: 1;
}

div.DTS div.dataTables_scrollBody table
{
	z-index: 2;
}

div.DTS div.dataTables_paginate,
div.DTS div.dataTables_length
{
	display: none;
}

.button:disabled,
button[disabled]
{
	cursor:not-allowed !important;
}

/* Datatable column control for WebPage, WebPageGroup ============================= */
div.dt-buttons
{
	position: relative;
}

/* div.dt-buttons.buttons-right
{
	float: right;
} */

div.dt-button-collection
{
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	margin-top: 3px;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.4);
	background-color: white;
	/* overflow: hidden; */
	z-index: 2002;
	border-radius: 5px;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	/* -webkit-column-gap: 8px; */
	/* -moz-column-gap: 8px; */
	/* -ms-column-gap: 8px; */
	/* -o-column-gap: 8px; */
	/* column-gap: 8px; */
}

div.dt-button-collection button.dt-button,
div.dt-button-collection div.dt-button,
div.dt-button-collection a.dt-button
{
	/* position: relative; */
	/* left: 0; */
	/* right: 0; */
	/* display: inline-block; */
	/* float: none; */
	text-align: center;
	font-size: 14px;
	border-color: black;
	margin: 0 2px 4px 2px;
	color :#9D9FA2;
}

div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection a.dt-button:active:not(.disabled),
div.dt-button-collection a.dt-button.active:not(.disabled)
{

	background-color: #a6c6ff; /*#dadada;*/
	border-radius: 5px;
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f0f0f0', EndColorStr='#dadada');
	box-shadow: inset 1px 1px 3px #666;
	color:black;
}

div.dt-button-collection.fixed
{
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -75px;
	border-radius: 0;
}

div.dt-button-collection.fixed.two-column
{
	margin-left: -150px;
}

div.dt-button-collection.fixed.three-column
{
	margin-left: -225px;
}

div.dt-button-collection.fixed.four-column
{
	margin-left: -300px;
}

div.dt-button-collection > *
{
	-webkit-column-break-inside: avoid;
	break-inside: avoid;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 8px 8px 4px 8px;
}

div.dt-button-collection.two-column
{
	width: 300px;
	padding-bottom: 1px;
	-webkit-column-count: 2;
	-moz-column-count: 2;
	-ms-column-count: 2;
	-o-column-count: 2;
	column-count: 2;
}

div.dt-button-collection.three-column
{
	width: 450px;
	padding-bottom: 1px;
	-webkit-column-count: 3;
	-moz-column-count: 3;
	-ms-column-count: 3;
	-o-column-count: 3;
	column-count: 3;
}

div.dt-button-collection.four-column
{
	width: 600px;
	padding-bottom: 1px;
	-webkit-column-count: 4;
	-moz-column-count: 4;
	-ms-column-count: 4;
	-o-column-count: 4;
	column-count: 4;
}

div.dt-button-background
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	/* Fallback */
	background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
	/* IE10 Consumer Preview */
	background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
	/* Firefox */
	background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
	/* Opera */
	background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0, 0, 0, 0.3)), color-stop(1, rgba(0, 0, 0, 0.7)));
	/* Webkit (Safari/Chrome 10) */
	background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
	/* Webkit (Chrome 11+) */
	background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
	/* W3C Markup, IE10 Release Preview */
	z-index: 2001;
}

@media screen and (max-width: 640px)
{
	div.dt-buttons
	{
		float: none !important;
		text-align: center;
	}
}

button.dt-button.processing,
div.dt-button.processing,
a.dt-button.processing
{
	color: rgba(0, 0, 0, 0.2);
}

button.dt-button.processing:after,
div.dt-button.processing:after,
a.dt-button.processing:after
{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
	box-sizing: border-box;
	display: block;
	content: ' ';
	border: 2px solid #282828;
	border-radius: 50%;
	border-left-color: transparent;
	border-right-color: transparent;
	animation: dtb-spinner 1500ms infinite linear;
	-o-animation: dtb-spinner 1500ms infinite linear;
	-ms-animation: dtb-spinner 1500ms infinite linear;
	-webkit-animation: dtb-spinner 1500ms infinite linear;
	-moz-animation: dtb-spinner 1500ms infinite linear;
}

.box
{
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	background: #fff;
	border-radius: 2px;
}

.widget-box
{
	padding: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin: 3px 0;
	border: 1px solid #CCC;
}

@media only screen and (max-width: 767px)
{
	.widget-box
	{
		margin-top: 7px;
		margin-bottom: 7px;
	}
}

.widget-header
{
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	position: relative;
	min-height: 38px;
	color: #669fc7;
	border-bottom: 1px solid #DDD;
	padding-left: 12px;
	text-align: left;
}

.widget-header:before,
.widget-header:after
{
	content: "";
	display: table;
	line-height: 0;
}

.widget-header:after
{
	clear: right;
}

.widget-box.collapsed > .widget-header
{
	border-bottom-width: 0;
}
.collapsed.fullscreen > .widget-header
{
	border-bottom-width: 1px;
}

.collapsed > .widget-body
{
	display: none;
}

.widget-header-flat
{
	background: #F7F7F7;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.widget-header-large
{
	min-height: 49px;
	padding-left: 18px;
}

.widget-header-small
{
	min-height: 31px;
	padding-left: 10px;
}

.widget-header > .widget-title
{
	line-height: 36px;
	padding: 0;
	margin: 0;
	display: inline;
}

.widget-header > .widget-title > .ace-icon
{
	margin-right: 5px;
	font-weight: normal;
	display: inline-block;
}

.widget-header-large > .widget-title
{
	line-height: 48px;
}

.widget-header-small > .widget-title
{
	line-height: 30px;
}

.widget-toolbar
{
	display: inline-block;
	padding: 0 10px;
	line-height: 37px;
	float: right;
	position: relative;
}

.widget-header-large > .widget-toolbar
{
	line-height: 48px;
}

.widget-header-small > .widget-toolbar
{
	line-height: 29px;
}

.widget-toolbar.no-padding
{
	padding: 0;
}

.widget-toolbar.padding-5
{
	padding: 0 5px;
}

.widget-toolbar:before
{
	display: inline-block;
	content: "";
	position: absolute;
	top: 3px;
	bottom: 3px;
	left: -1px;
	border: 1px solid #D9D9D9;
	border-width: 0 1px 0 0;
}

.widget-header-large > .widget-toolbar:before
{
	top: 6px;
	bottom: 6px;
}
[class*="widget-color-"] > .widget-header > .widget-toolbar:before
{
	border-color: #EEE;
}

.widget-color-orange > .widget-header > .widget-toolbar:before
{
	border-color: #FEA;
}

.widget-color-dark > .widget-header > .widget-toolbar:before
{
	border-color: #222;
	box-shadow: -1px 0 0 rgba(255, 255, 255, 0.2), inset 1px 0 0 rgba(255, 255, 255, 0.1);
}

.widget-toolbar.no-border:before
{
	display: none;
}

.widget-toolbar label
{
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 0;
}

.widget-toolbar > a,
.widget-toolbar > .widget-menu > a
{
	font-size: 14px;
	margin: 0 1px;
	display: inline-block;
	padding: 0;
	line-height: 24px;
}

.widget-toolbar > a:hover,
.widget-toolbar > .widget-menu > a:hover
{
	text-decoration: none;
}

.widget-header-large > .widget-toolbar > a,
.widget-header-large > .widget-toolbar > .widget-menu > a
{
	font-size: 15px;
	margin: 0 1px;
}

.widget-toolbar > .btn
{
	line-height: 27px;
	margin-top: -2px;
}
.widget-toolbar > .btn.smaller
{
	line-height: 26px;
}
.widget-toolbar > .btn.bigger
{
	line-height: 28px;
}
.widget-toolbar > .btn-sm
{
	line-height: 24px;
}

.widget-toolbar > .btn-sm.smaller
{
	line-height: 23px;
}

.widget-toolbar > .btn-sm.bigger
{
	line-height: 25px;
}

.widget-toolbar > .btn-xs
{
	line-height: 22px;
}

.widget-toolbar > .btn-xs.smaller
{
	line-height: 21px;
}

.widget-toolbar > .btn-xs.bigger
{
	line-height: 23px;
}

.widget-toolbar > .btn-minier
{
	line-height: 18px;
}

.widget-toolbar > .btn-minier.smaller
{
	line-height: 17px;
}

.widget-toolbar > .btn-minier.bigger
{
	line-height: 19px;
}

.widget-toolbar > .btn-lg
{
	line-height: 36px;
}

.widget-toolbar > .btn-lg.smaller
{
	line-height: 34px;
}

.widget-toolbar > .btn-lg.bigger
{
	line-height: 38px;
}

.widget-toolbar-dark
{
	background: #444;
}

.widget-toolbar-light
{
	background: rgba(255, 255, 255, 0.85);
}

.widget-toolbar > .widget-menu
{
	display: inline-block;
	position: relative;
}

.widget-toolbar > a[data-action],
.widget-toolbar > .widget-menu > a[data-action]
{
	-webkit-transition: transform 0.1s;
	-o-transition: transform 0.1s;
	transition: transform 0.1s;
}

.widget-toolbar > a[data-action] > .ace-icon,
.widget-toolbar > .widget-menu > a[data-action] > .ace-icon
{
	margin-right: 0;
}

.widget-toolbar > a[data-action]:focus,
.widget-toolbar > .widget-menu > a[data-action]:focus
{
	text-decoration: none;
	outline: none;
}

.widget-toolbar > a[data-action]:hover,
.widget-toolbar > .widget-menu > a[data-action]:hover
{
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

.widget-body
{
	background-color: #FFF;
}

.widget-main
{
	padding: 12px;
}

.widget-main.padding-0
{
	padding: 0px;
}

.widget-main.no-padding
{
	padding: 0;
}

.widget-toolbar .progress
{
	vertical-align: middle;
	display: inline-block;
	margin: 0;
}

.widget-toolbar > .dropdown,
.widget-toolbar > .dropup
{
	display: inline-block;
}

.widget-box > .widget-header > .widget-toolbar > [data-action="settings"],
.widget-color-dark > .widget-header > .widget-toolbar > [data-action="settings"],
.widget-box > .widget-header > .widget-toolbar > .widget-menu > [data-action="settings"],
.widget-color-dark > .widget-header > .widget-toolbar > .widget-menu > [data-action="settings"]
{
	color: #99CADB;
}

.widget-box > .widget-header > .widget-toolbar > [data-action="reload"],
.widget-color-dark > .widget-header > .widget-toolbar > [data-action="reload"],
.widget-box > .widget-header > .widget-toolbar > .widget-menu > [data-action="reload"],
.widget-color-dark > .widget-header > .widget-toolbar > .widget-menu > [data-action="reload"]
{
	color: #ACD392;
}

.widget-box > .widget-header > .widget-toolbar > [data-action="collapse"],
.widget-color-dark > .widget-header > .widget-toolbar > [data-action="collapse"],
.widget-box > .widget-header > .widget-toolbar > .widget-menu > [data-action="collapse"],
.widget-color-dark > .widget-header > .widget-toolbar > .widget-menu > [data-action="collapse"]
{
	color: #AAA;
}

.widget-box > .widget-header > .widget-toolbar > [data-action="close"],
.widget-color-dark > .widget-header > .widget-toolbar > [data-action="close"],
.widget-box > .widget-header > .widget-toolbar > .widget-menu > [data-action="close"],
.widget-color-dark > .widget-header > .widget-toolbar > .widget-menu > [data-action="close"]
{
	color: #E09E96;
}

.widget-box[class*="widget-color-"] > .widget-header
{
	color: #FFF;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.widget-box.transparent
{
	border-width: 0;
}

.widget-box.transparent > .widget-header
{
	background: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	border-width: 0;
	border-bottom: 1px solid #DCE8F1;
	color: #4383B4;
	padding-left: 3px;
}

.widget-box.transparent > .widget-header-large
{
	padding-left: 5px;
}

.widget-box.transparent > .widget-header-small
{
	padding-left: 1px;
}

.widget-box.transparent > .widget-body
{
	border-width: 0;
	background-color: transparent;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > [data-action],
[class*="widget-color-"] > .widget-header > .widget-toolbar > .widget-menu > [data-action]
{
	text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > [data-action="settings"],
[class*="widget-color-"] > .widget-header > .widget-toolbar > .widget-menu > [data-action="settings"]
{
	color: #D3E4ED;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > [data-action="reload"],
[class*="widget-color-"] > .widget-header > .widget-toolbar > .widget-menu > [data-action="reload"]
{
	color: #DEEAD3;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > [data-action="collapse"],
[class*="widget-color-"] > .widget-header > .widget-toolbar > .widget-menu > [data-action="collapse"]
{
	color: #E2E2E2;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > [data-action="close"],
[class*="widget-color-"] > .widget-header > .widget-toolbar > .widget-menu > [data-action="close"]
{
	color: #FFD9D5;
}

.widget-color-orange > .widget-header > .widget-toolbar > [data-action],
.widget-color-orange > .widget-header > .widget-toolbar > .widget-menu > [data-action]
{
	text-shadow: none;
}

.widget-color-orange > .widget-header > .widget-toolbar > [data-action="settings"],
.widget-color-orange > .widget-header > .widget-toolbar > .widget-menu > [data-action="settings"]
{
	color: #559AAB;
}

.widget-color-orange > .widget-header > .widget-toolbar > [data-action="reload"],
.widget-color-orange > .widget-header > .widget-toolbar > .widget-menu > [data-action="reload"]
{
	color: #7CA362;
}

.widget-color-orange > .widget-header > .widget-toolbar > [data-action="collapse"],
.widget-color-orange > .widget-header > .widget-toolbar > .widget-menu > [data-action="collapse"]
{
	color: #777;
}

.widget-color-orange > .widget-header > .widget-toolbar > [data-action="close"],
.widget-color-orange > .widget-header > .widget-toolbar > .widget-menu > [data-action="close"]
{
	color: #A05656;
}

.widget-box.light-border[class*="widget-color-"]:not(.fullscreen)
{
	border-width: 0;
}

.widget-box.light-border[class*="widget-color-"]:not(.fullscreen) > .widget-header
{
	border: 1px solid;
	border-color: inherit;
}

.widget-box.light-border[class*="widget-color-"]:not(.fullscreen) > .widget-body
{
	border: 1px solid;
	border-color: #D6D6D6;
	border-width: 0 1px 1px;
}

.widget-box.no-border
{
	border-width: 0;
}

.widget-box.fullscreen
{
	position: fixed;
	margin: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #FFF;
	border-width: 3px;
	z-index: 1040 !important;
}

.widget-box.fullscreen:not([class*="widget-color-"])
{
	border-color: #AAA;
}

.widget-body .table
{
	border-top: 1px solid #E5E5E5;
}

[class*="widget-color-"] > .widget-body .table thead:first-child tr
{
	background: #f2f2f2;
	background-image: -webkit-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
	background-image: -o-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
	background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
}

.widget-body .table.table-bordered thead:first-child > tr
{
	border-top-width: 0;
}

.widget-main.no-padding .table
{
	margin-bottom: 0;
	border-width: 0;
}
.widget-main.no-padding .table-bordered th:first-child,
.widget-main.no-padding .table-bordered td:first-child
{
	border-left-width: 0;
}

.transparent > .widget-body .widget-main .table-bordered > thead > tr > th:last-child,
.widget-main.no-padding .table-bordered > thead > tr > th:last-child,
.transparent > .widget-body .widget-main .table-bordered > tbody > tr > td:last-child,
.widget-main.no-padding .table-bordered > tbody > tr > td:last-child,
.transparent > .widget-body .widget-main .table-bordered > tfoot > tr > td:last-child,
.widget-main.no-padding .table-bordered > tfoot > tr > td:last-child
{
	border-right-width: 0 !important;
}

.transparent > .widget-body .widget-main .table-bordered > tbody > tr:last-child > td,
.widget-main.no-padding .table-bordered > tbody > tr:last-child > td
{
	border-bottom-width: 0 !important;
}

.table-bordered > thead.thin-border-bottom > tr > th,
.table-bordered > thead.thin-border-bottom > tr > td
{
	border-bottom-width: 1px;
}

.widget-body .alert:last-child
{
	margin-bottom: 0;
}

.widget-main .tab-content
{
	border-width: 0;
}

.widget-toolbar > .nav-tabs
{
	border-bottom-width: 0;
	margin-bottom: 0;
	top: auto;
	margin-top: 3px !important;
}

.widget-toolbar > .nav-tabs > li
{
	margin-bottom: auto;
}

.widget-toolbar > .nav-tabs > li > a
{
	box-shadow: none;
	position: relative;
	top: 1px;
	margin-top: 1px;
}

.widget-toolbar > .nav-tabs > li:not(.active) > a
{
	border-color: transparent;
	background-color: transparent;
}

.widget-toolbar > .nav-tabs > li:not(.active) > a:hover
{
	background-color: transparent;
}

.widget-toolbar > .nav-tabs > li.active > a
{
	background-color: #FFF;
	border-bottom-color: transparent;
	box-shadow: none;
	margin-top: auto;
}

.widget-header-small > .widget-toolbar > .nav-tabs > li > a
{
	line-height: 16px;
	padding-top: 6px;
	padding-bottom: 6px;
}

.widget-header-small > .widget-toolbar > .nav-tabs > li.active > a
{
	border-top-width: 2px;
}

.widget-header-large > .widget-toolbar > .nav-tabs > li > a
{
	line-height: 22px;
	padding-top: 9px;
	padding-bottom: 9px;
	margin-top: 4px;
}

.widget-header-large > .widget-toolbar > .nav-tabs > li.active > a
{
	margin-top: 3px;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > .nav-tabs > li > a
{
	border-color: transparent;
	background-color: transparent;
	color: #FFF;
	margin-right: 1px;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > .nav-tabs > li > a:hover
{
	background-color: #FFF;
	color: #555;
	border-top-color: #FFF;
}

[class*="widget-color-"] > .widget-header > .widget-toolbar > .nav-tabs > li.active > a
{
	background-color: #FFF;
	color: #555;
	/*border-top-width: 1px;*/
	margin-top: 0;
}

.widget-toolbar > .nav-tabs .widget-color-orange > .widget-header > li > a
{
	color: #855D10;
}

.transparent > .widget-header > .widget-toolbar > .nav-tabs > li > a
{
	color: #555;
	background-color: transparent;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
}

.transparent > .widget-header > .widget-toolbar > .nav-tabs > li.active > a
{
	border-top-color: #4C8FBD;
	border-right: 1px solid #C5D0DC;
	border-left: 1px solid #C5D0DC;
	background-color: #FFF;
	box-shadow: none;
}

.widget-toolbox
{
	background-color: #EEE;
}

.widget-toolbox:first-child
{
	padding: 2px;
	border-bottom: 1px solid #CCC;
}

.widget-toolbox:last-child
{
	padding: 2px;
	border-top: 1px solid #CCC;
}

.transparent > .widget-body > .widget-toolbox:last-child
{
	border: none;
	border-top: 1px solid #CCC;
}

.widget-toolbox > .btn-toolbar
{
	margin: 0 !important;
	padding: 0;
}

.widget-toolbox.center
{
	text-align: center;
}

.widget-toolbox.toolbox-vertical
{
	border-bottom-width: 0;
	border-right: 1px solid #D9D9D9;
	display: table-cell;
	padding: 6px 4px;
	vertical-align: top;
	width: 1px;
}

.widget-toolbox.toolbox-vertical + .widget-main
{
	display: table-cell;
	vertical-align: top;
}

.widget-toolbox.padding-16
{
	padding: 16px;
}

.widget-toolbox.padding-14
{
	padding: 14px;
}

.widget-toolbox.padding-12
{
	padding: 12px;
}

.widget-toolbox.padding-10
{
	padding: 10px;
}
.widget-toolbox.padding-8
{
	padding: 8px;
}

.widget-toolbox.padding-6
{
	padding: 6px;
}

.widget-toolbox.padding-4
{
	padding: 4px;
}

.widget-toolbox.padding-2
{
	padding: 2px;
}

.widget-toolbox.padding-0
{
	padding: 0px;
}

.widget-box-overlay
{
	position: absolute;
	top: -1px;
	bottom: -1px;
	right: -1px;
	left: -1px;
	z-index: 999;
	text-align: center;
	min-height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
}

.widget-box-overlay > .loading-icon
{
	position: relative;
	top: 20%;
	left: 0;
	right: 0;
	text-align: center;
}

.widget-box.collapsed .widget-box-overlay > .loading-icon
{
	top: 10%;
}

.widget-box-overlay > .loading-icon.icon-spin
{
	-moz-animation-duration: 1.2s;
	-webkit-animation-duration: 1.2s;
	-o-animation-duration: 1.2s;
	-ms-animation-duration: 1.2s;
	animation-duration: 1.2s;
}

.widget-main > form
{
	margin-bottom: 0;
}

.widget-main > form .input-append,
.widget-main > form .input-prepend
{
	margin-bottom: 0;
}

.widget-main.no-padding > form > fieldset,
.widget-main.padding-0 > form > fieldset
{
	padding: 16px;
}

.widget-main.no-padding > form > fieldset + .form-actions,
.widget-main.padding-0 > form > fieldset + .form-actions
{
	padding: 10px 0 12px;
}

.widget-main.no-padding > form > .form-actions,
.widget-main.padding-0 > form > .form-actions
{
	margin: 0;
	padding: 10px 12px 12px;
}
.widget-placeholder
{
	border: 2px dashed #D9D9D9;
}

@media (max-width: 767px)
{
	.widget-container-col .widget-body
	{
		padding-left: 20px;
	}
	.aligntoSlider
	{
		margin-top: 0;
	}
}

@media (max-width: 1345px)
{
	.widget-container-col .widget-body
	{
		overflow: auto;
	}
}

.no-padding-right
{
	padding-right: 0 !important;
}

.input-icon
{
	position: relative;
}

.input-icon.input-icon-right > .ace-icon
{
	left: auto;
	right: 5px;
}

.input-icon > .ace-icon
{
	padding: 0 3px;
	z-index: 2;
	position: absolute;
	top: 10px;
	bottom: 10px;
	left: 3px;
	line-height: 30px;
	display: inline-block;
	color: #909090;
	font-size: 18px;
}

.infobox-container {
	text-align: center;
	font-size: 0;
  }
  .infobox {
	display: inline-block;
	width: 235px; /*230*/
	height: 90px; /*66*/
	color: #555;
	background-color: #FFF;
	box-shadow: none;
	border-radius: 0;
	margin: -1px 0 0 -1px;
	padding: 8px 3px 6px 9px;
	border: 1px dotted;
	border-color: #D8D8D8 !important;
	vertical-align: middle;
	text-align: left;
	position: relative;
  }
  .infobox > .infobox-icon {
	display: inline-block;
	vertical-align: top;
	width: 44px;
  }
  .infobox > .infobox-icon > .ace-icon {
	display: inline-block;
	height: 42px;
	margin: 0;
	padding: 1px 1px 0 2px;
	background-color: transparent;
	border-width: 0;
	text-align: center;
	position: relative;
	border-radius: 100%;
	-webkit-box-shadow: 1px 1px 0 rgba(0,0,0,0.2);
	box-shadow: 1px 1px 0 rgba(0,0,0,0.2);
  }
  .infobox > .infobox-icon > .ace-icon:before {
	font-size: 24px;
	display: block;
	padding: 6px 0 7px;
	width: 40px;
	text-align: center;
	border-radius: 100%;
	color: #FFF;
	color: rgba(255, 255, 255, 0.9);
	background-color: transparent;
	background-color: rgba(255, 255, 255, 0.2);
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
  }
  .infobox .infobox-content {
	color: #555;
	/*max-width: 140px;*/
  }
  .infobox .infobox-content:first-child {
	font-weight: bold;
  }
  .infobox > .infobox-data {
	display: inline-block;
	border-width: 0;
	border-top-width: 0;
	font-size: 13px;
	text-align: left;
	line-height: 21px;
	min-width: 130px;
	padding-left: 8px;
	position: relative;
	top: 0;
  }
  .infobox > .infobox-data > .infobox-data-number {
	display: block;
	font-size: 22px;
	margin: 2px 0 4px;
	position: relative;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
  }
  .infobox > .infobox-data > .infobox-text {
	display: block;
	font-size: 16px;
	margin: 2px 0 10px; /* 2px 0 4px*/
	position: relative;
	text-shadow: none;
  }
  .infobox.no-border {
	border-width: 0 !important;
  }
  @media only screen and (max-width: 479px) {
	.infobox {
	  min-width: 95%;
	  margin-top: 2px;
	  margin-bottom: 2px;
	}
  }

.infobox-0.infobox-dark
{
	background-color: #034F84;
	border-color: #034F84;
}

.infobox-1.infobox-dark
{
	background-color: #638BBF;
	border-color: #638BBF;
}

.infobox-2.infobox-dark
{
	background-color: #F38690;
	border-color: #F38690;
}

.infobox-7.infobox-dark
{
	background-color: #5A8D80;
	border-color: #5A8D80;
}

.infobox-3.infobox-dark
{
	background-color: #CC584E;
	border-color: #CC584E;
}

.infobox-4.infobox-dark
{
	background-color: #FF6F61;
	border-color: #FF6F61;
}

.infobox-5.infobox-dark
{
	background-color: #9B3E45;
	border-color: #9B3E45;
}

.infobox-6.infobox-dark
{
	background-color: #8E6082;
	border-color: #8E6082;
}

.infobox-dark
{
	margin: 1px 1px 0 0;
	border-color: transparent !important;
	border-width: 0;
	color: #FFF;
	padding: 4px;
}

.infobox-dark .infobox-content
{
	color: #FFF;
}

.infobox-small
{
	/*width: 135px;*/
	height: 52px;
	text-align: left;
	padding-bottom: 5px;
}

.infobox-small > .infobox-data
{
	display: inline-block;
	text-align: left;
	vertical-align: middle;
	/*max-width: 72px;*/
	min-width: 0;
}

.tableBorder
{
	border: 1px solid #555;
}

.text_bold
{
	font-weight: bold;
}

.formLabel
{
	display: block;
	font-size: 12px;
	color: #808080;
	margin-bottom: 0.25rem;
}

.searchBtn
{
	margin-left:5px !important;
	vertical-align: bottom !important;
}

.iconSpace
{
	margin-right:5px
}

.eip_bg
{
	background-color: #eeeeee !important;
}

/* Editor */
/* table �撘�*/
.editorContent
{
	overflow: auto;
}

.editorContent table
{
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
}

.editorContent table td,
.editorContent table th
{
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 3px 5px;
}

.editorContent table th
{
	border-bottom: 2px solid #ccc;
	text-align: center;
	background-color: #f1f1f1;
}

.editorContent table td
{
	background-color: #ffffff;
}

/* blockquote 嚙踐僭��蕭 */
.editorContent blockquote
{
	display: block;
	border-left: 8px solid #d0e5f2;
	padding: 5px 10px;
	margin: 10px 0;
	line-height: 1.4;
	font-size: 100%;
	background-color: #f1f1f1;
}

/* code 嚙踐僭��蕭 */
.editorContent code
{
	display: inline-block;
	*display: inline;
	*zoom: 1;
	background-color: #f1f1f1;
	border-radius: 3px;
	padding: 3px 5px;
	margin: 0 3px;
}

.editorContent pre code
{
	display: block;
}

.editorContent img
{
	height: auto;
}

.clickable
{
	color:#0058A9 !important;
	cursor: pointer;
	font-weight: bold;
}

.btn-primary,
.fc-button-primary
{
	background-color: #0058A9 !important;
	border-color: #0058A9 !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:visited,
.fc-button-primary:hover, .fc-button-primary:active, .fc-button-primary:visited,
.activeTypeTab
{
	background-color: #08355F !important;
	border-color: #08355F !important;
}

.btn-outline-primary, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:visited
{
	color: #0058A9 !important;
	border-color: #0058A9 !important;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:visited
{
	background-color: #0058A9 !important;
	color: #ffffff !important;
}

.fc .fc-button
{
	padding: 0;
}

.fc .fc-toolbar.fc-header-toolbar
{
	margin-bottom: 0.5em;
}

.page-item.active .page-link
{
	background-color: #0058A9 !important;
	border-color: #0058A9 !important;
	color: #fff !important;
}

.page-link
{
	color: #0058A9 !important;
}

.vc-popover-content-wrapper
{
	z-index: 20000 !important;
}

.label_no_margin
{
	margin: 0;
}

.loadImg
{
	height:9em;
	min-width:16em;
	word-break: break-word;
}

.wordBreak
{
	word-break: break-all;
}

.space_for_newIcon
{
	margin-left: 5px;
}

.hide_link
{
	display: none;
}
/* Calendar ============================= */
.vc-rounded
{
	border-radius: 0.5rem;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child)
{
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* Carousel ============================= */
.carousel-control-prev-icon
{
	width: 2rem;
	height: 2rem;
}

.carousel-control-next-icon
{
	width: 2rem;
	height: 2rem;
}

/* Media ============================= */
.img-fluid-meeting-room
{
    max-width: 100%;
    height: 220px;
}

.vib-hidden
{
    opacity: 100 !important;
}

@media (max-width: 800px)
{
	.vib-content video
	{
		width: 100%;
	}
}

/* Utilities ============================= */
.min-content
{
	width: min-content;
}