@import "~@/css/variables.scss";
// for modify plugin css, not put plugin css

// bootstrap
.b-sidebar
{
	height: 100%;
}

// vue-image-lightbox-carousel
.homePage .vib-arrow
{
	top: calc(50% + 30px);
}

// full calendar
#fullcalendar
{
	.fc-event-title
	{
		font-size: 12px;
	}
	.fc-timegrid-event-harness
	{
		inset-inline-end: inherit !important;
	}
}

// bootstrap
.tabs
{
	.nav-tabs
	{
		font-weight: bold;
		.nav-link
		{
			&:focus, &:active, &.active
			{
				color: #0058A9;
				border-color: transparent transparent#0058A9 transparent;
				background-color: transparent;
			}
		}
	}
	.tab-content
	{
		padding: 20px 10px;
	}
}

// bootstrap
.dropdown
{
	&.country-code
	{
		@extend %resume-input-basic-style;
		padding: 0;
		background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;

		button
		{
			color: #111;
			border: 0;
			padding: 0.75rem 0.875rem;
			display: flex;
			align-items: center;
			text-decoration: none;
		}
		.flag-icon { margin-right: 0.5rem; }
		.dropdown-menu
		{
			width: 100%;
			max-height: 40vh;
			overflow-y: auto;

			.dropdown-item
			{
				display: flex;
				align-items: center;

				&.active, &:focus
				{
					color: #fff;
				}
			}
		}
	}
}

// v-calendar
.v-date-picker
{
	padding: 8px 12px;
	background-image: none;
}

// vue-cropper
.vue-cropper-bg
{
	@extend %flex-center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	position: fixed;
	background: rgba(0, 0, 0, 0.75);

	.vue-cropper-wrapper
	{
		@extend %resume-modal-shadow;
		width: 500px;
		height: 620px;
		padding: 30px;
		display: flex;
		background: #FFFFFF;
		border-radius: 20px;
		flex-direction: column;

		h3
		{
			font-size: 24px;
			font-weight: 700;
			line-height: 26px;
			margin-bottom: 30px;
		}
	}
	.vue-cropper
	{
		margin-bottom: 30px;
		background-image: none;
		background-color: #f0f0f0;
	}
	.btns
	{
		display: flex;
		justify-content: flex-end;

		button.btn
		{
			@extend %flex-center;
			color: #fff;
			padding: 8px 12px;
			line-height: 18px;
			margin-left: 10px;

			&.btn-cancel
			{
				background-color: #6C757D;
			}
			&.btn-crop
			{
				background-color: $candidate-color-primary;
			}
		}
	}
}

// datatable for fixed column
// remove class .personal-info-mgnt if EIP need
.personal-info-mgnt .dataTables_wrapper
{
	.dataTables_scrollHead.width-control
	{
		width: calc(100% - 10px) !important;
	}

	.odd
	{
		.dtfc-fixed-right, .dtfc-fixed-left { background-color: #f2f2f2; }
		&:hover
		{
			.dtfc-fixed-right, .dtfc-fixed-left { background-color: #ececec; }
		}
	}

	.even
	{
		.dtfc-fixed-right, .dtfc-fixed-left { background-color: #fff; }
		&:hover
		{
			.dtfc-fixed-right, .dtfc-fixed-left { background-color: #ececec; }
		}
	}
}

@media screen and (max-width: 767px)
{
	.personal-info-mgnt .dataTables_wrapper
	{
		.dataTables_scrollHead.width-control
		{
			width: 100% !important;
		}
	}
}