.loading
{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 99999;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.3);
}
.loading img
{
    width: 50px;
    height: 50px;
    margin-top: 40vh;
}