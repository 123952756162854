#app
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	font-family: "Microsoft JhengHei";
	font-size: 16px;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

@media (max-width: 576px)
{
	#app
	{
		height: initial;
		display: block;
	}
}

#nav
{
	padding: 30px;
}

#nav a
{
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active
{
	color: #42b983;
}

/* Burger */
.hidden
{
	visibility: hidden;
}

button
{
	cursor: pointer;
}

/* remove blue outline */
button:focus
{
	outline: 0;
}

/* .menuContainer
{
	background-color: #eaeaea;
} */

#burger
{
	padding: 4px;
	width: 1%;
}

.burger-button
{
	position: relative;
	height: 32px;
	width: 35px;
	display: block;
	z-index: 999;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	pointer-events: all;
	transition: transform .6s cubic-bezier(.165,.84,.44,1);
}

.burger-bar
{
	background-color: #ffffff;
	position: absolute;
	top: 50%;
	right: 0px;
	left: 6px;
	height: 3px;
	width: 30px;
	transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
}

.burger-bar--1
{
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
}

.burger-bar--2
{
	transform-origin: 100% 50%;
	transform: scaleX(.8);
}

.burger-button:hover .burger-bar--2
{
	transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover
{
	transform: scaleX(1);
}

.burger-bar--3
{
	transform: translateY(10px);
}

#burger.active .burger-button
{
	transform: rotate(-180deg);
}

#burger.active .burger-bar
{
	background-color: #fff;
}

#burger.active .burger-bar--1
{
	transform: rotate(45deg)
}

#burger.active .burger-bar--2
{
	opacity: 0;
}

#burger.active .burger-bar--3
{
	transform: rotate(-45deg)
}

/* SideBar ============================= */
.sidebar-no-header-title
{
	font-weight: bold;
	display: inline-block;
	margin-right: 5px;
}

.sidebar_status
{
	font-weight: bold;
	display: inline-block;
}

.sidebar_subtitle
{
	border-bottom:1px solid #555;
	padding-bottom:2px;
}

.slide-enter-active,
.slide-leave-active
{
	transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to
{
	transform: translateX(-100%);
	transition: all 150ms ease-in 0s
}

.sidebar-backdrop
{
	background-color: rgba(0,0,0,.5);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	cursor: pointer;
	z-index: 5;
}

.sidebar-panel-nav
{
	float: left;
	text-align: center;
	font-size: 20px;
	width: 100%;
}

.sidebar-panel
{
	/* overflow-y: auto; */
	background-color: #334858;
	position: absolute;
	left: 0;
	top: 32px;
	height: 97vh;
	z-index: 999;
	padding: 2rem 20px;
	min-width: 300px;
}

.treeViewContainer
{
	height: 75vh;
	overflow: auto;
}

/* Active ============================= */
.msg_serverDone
{
	display: block;
}

/* button */
.buttonContainer
{
	position: absolute;
	top: 3px;
	right: 5px;
}

.buttonSpace
{
	margin-right:0.25rem;
}

.logoutBtn
{
	margin-top:10px;
	border-top:1px solid #fff;
	padding-top:10px
}

/* File Upload */
.dropZone {
	height: 200px;
	position: relative;
	border: 2px dashed #eee;
}

.dropZone:hover {
	border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
	color: #1975A0;
}

.dropZone-info {
	color: #A8A8A8;
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	text-align: center;
}

.dropZone-title {
	color: #787878;
}

.dropZone input {
	position: absolute;
	cursor: pointer;
	top: 0px;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.dropZone-upload-limit-info {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.dropZone-over {
	background: #5C5C5C !important;
	opacity: 0.8;
	border: 2px solid #2e94c4;
}

.dropZone-uploaded {
	height: 200px;
	position: relative;
	border: 2px dashed #eee;
}

.dropZone-uploaded-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #A8A8A8;
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	text-align: center;
}

.removeFile {
	width: 200px;
}

.material .snotifyToast__title
{
	font-size: 1.3em !important;
}

.fileFormatExTable td
{
	border: 1px solid #dee2e6;
	text-align: center;
}

.confirmDevice
{
	text-align: center;
	background-color: #f9f9f9;
	margin: 2% 0;
}

.text-danger
{
	color:#a94442 !important;
	font-size: 0.75rem;
}

.deviceListDataDiv
{
	margin: 0 auto;
	width: 90%;
}

.deviceItemSpan
{
	padding: 5px;
	border: 1px solid #393939;
	text-align: left;
	display:inline-block;
	height: 34px;
	min-width: 50px;
	font-size: 14px;
	vertical-align: middle;
}

.connectionFlag
{
	color: #fff;
	border-radius: 5px;
	padding: 5px;
	font-size: 15px;
}

.on
{
	background-color: #87b87f;
}

.off
{
	background-color: #d15b47 ;
}

.modal-title
{
	color:#334858;
	font-weight: 600;
}

.snotify
{
	width: 400px !important;
}

.snotify-icon--error
{
	background: none !important;
}

.snotifyToast__body
{
	text-align: start !important;
	word-break: break-word;
}

.inputGroupBg
{
	background-color: rgb(249, 249, 249);
	padding: 1%;
}

.inputGroupBg_Dark
{
	background-color: #efefef;
	padding: 3%;
}

.deleteMeter ul
{
	background-color: #ffe6e6;
}

.activeCondition
{
	color: #fff;
	background-color: #545b62;
	border-color: #4e555b;
}

.buttonGroup
{
	font-size: 14px;
	border-left: 2px solid rgb(3, 73, 146);
}

.boardContainer
{
	background-color: #f3f3f3;
	padding: 1%;
}

.activeBoard
{
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
}

.activeTable,
.activeBar
{
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.container
{
	width: 100%;
	margin: 0 auto;
	text-align: left;
}

.dataUpdateTimeStyle
{
	color:#555;
	position: absolute;
	top: 5%;
	right: 6%;
	margin-right: 5px;
}

.refreshBtnPos
{
	position: absolute;
	top: 8%;
	right: 1%;
}

.progressbar_title_Mid
{
	font-size: 16px;
	font-weight: bolder;
}

.progressbar_title
{
	font-size: 20px;
	font-weight: bolder;
}
.progress
{
	margin:0;
	box-shadow: none;
	background: #bebebe;
}

.progress .progress-bar
{
	-webkit-box-shadow: none;
	box-shadow: none;
	line-height: 25px;
}

.progress-bar
{
	overflow: visible !important;
}

#loading_ConnectionRate
{
	position: absolute;
	left: calc(44% - 26px);
	top: calc(28% - 10px);
	z-index: 9999;
	width: 15%;
}

#connectionRate_RF_MUTIHOP,
#midSuccessRate_RF_MUTIHOP
{
	color:#98393F
}

#connectionRate_ONE_TO_N,
#midSuccessRate_ONE_TO_N
{
	color:#4E6283
}

#connectionRate_PLC,
#midSuccessRate_PLC
{
	color:#55736D
}

#progressBar_RF_MUTIHOP
{
	background-color: #98393F;
}

#progressBar_ONE_TO_N
{
	background-color: #4E6283;
}

#progressBar_PLC
{
	background-color: #55736D;
}

.flex-container
{
	display: flex;
	flex-direction :row;
	justify-content:center;
	padding: 15px;
}

.flex-box
{
	width: 20%;
	height: 240px;
	margin: 1px;
}

.flex-box-bg
{
	background-color: #616365;
	margin: 8px 15px 0 15px;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	transition-delay: 0.2s;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	border-radius: 10px;
	font-size: 35px;
	color: #FFFFFF;
	line-height: 240px;
	font-weight: 600;
}

@media screen and (max-width: 1440px)
{
	.smallTitle {
		font-size: 16px;
	}
}

.isNG
{
	background-color: #d15b47;
	cursor: pointer;
}

.isNG:focus
{
	background-color: #A53629;
	outline: none;
}

.isOk
{
	background-color: #85b558;
	cursor: pointer;
}

.isOk:focus
{
	background-color: #568C20;
	outline: none;
}

.popover-header
{
	margin: 0;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
}

.isOkTitle,
.OK
{
	color: #85b558;
	font-size: 16px;

}

.isNGTitle,
.NG
{
	color: #d15b47;
	font-size: 16px;
}

.contentTitle
{
	color:grey;
	font-size:18px;
	font-weight: 600;
}

#loading_SystemStaus
{
	position: absolute;
	left: calc(44% - 10px);
	top: calc(40% - 10px);
	z-index: 9999;
	width: 15%;
}

#loading_OnlineRate,
#loading_MidSuccessRate,
#loading_AlertSummary
{
	position: absolute;
	left: calc(44% - 10px);
	top: calc(40% - 10px);
	z-index: 9999;
	width: 9%;
}

.boxStyle
{
	margin:5px;
	padding: 5px;
	cursor: pointer;
}

.boxStyle.active, .boxStyle:hover ,.boxStyle:focus
{
	-webkit-transform: scale(1.05);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	transform: scale(1.05);
}

.title_alertStatus
{
	font-weight:600;
	font-size:18px;
}
.moreInfo
{
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	color: #669fc7;
}
.alertRank
{
	display:inline-block;
}

.divContainer
{
	position:relative;
}

.alertType>span
{
	position: absolute;
	right: 0;
	bottom: 100%;
}

.alertRank_Abnormal
{
	color: #F2CB05;
}

.type
{
	display: flex;
	justify-content: space-between;
}

.right
{
	float: right;
}

.blockSpace
{
	margin-top: 10px;
}

.lineChart
{
	width: 100%;
	max-width: 100%;
	max-height: 300px;
	margin-bottom: 15%;
}

.linkColor
{
	color:#669fc7;
}

.linkColor:hover
{
	color: #4E6283;
}

.controlBtn
{
	position: absolute;
	right: 15px;
	top: -45px;
}
.dateColWidth
{
	min-width: 100px;
}
.date-range .row
{
	margin: 0;
	display: grid;
    grid-gap: 6px;
    grid-template-columns: 1fr 1fr;
}

.bold
{
	font-weight: 600;
}

.lastUpdateTimeStyle
{
	color: #555;
	position: absolute;
	top: -10px;
	right: 10px;
	font-weight: bold;
}

.midContainer
{
	border-bottom: 1px solid #e3dfdf;
	margin-bottom: 1% !important;
	padding-bottom: 1%;
}

.progressbar_title_Mid
{
	font-size: 16px;
	font-weight: bolder;
}

.successRateUnder
{
	color:#A65A53;
}

/* Login */
.login-page
{
	padding: 8% 0 0;
	margin: auto;
	text-align: center;
}

.form-login
{
	position: relative;
	z-index: 1;
	background: #FFFFFF;
	max-width: 360px;
	margin: 0 auto 100px;
	padding: 40px;
	text-align: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form-reset
{
	position: relative;
	z-index: 1;
	background: #FFFFFF;
	max-width: 450px;
	margin: 0 auto 100px;
	padding: 40px;
	text-align: center;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.form-login input,
.form-reset input
{
	outline: 0;
	background: #f2f2f2;
	width: 100%;
	border: 0;
	margin: 0 0 15px;
	padding: 15px;
	box-sizing: border-box;
	font-size: 16px;
}

.loginBtn
{
	display: inline-block;
	padding: .75rem 1.25rem;
	font-family: "Roboto", sans-serif;
	color: #fff;
	outline: 0;
	border: 0;
	width: 100%;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: .15rem;
	transition: all .3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.loginBtn:after
{
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #556068;
	z-index: -2;
}

.loginBtn:before
{
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background-color: #8ea1af;
	transition: all .3s;
	z-index: -1;
}

.loginBtn:hover
{
	color: #fff;
}

.loginBtn:hover:before
{
	width: 100%;
}

.loginBtn:disabled::after
{
	background-color: #cccccc;
}

.loginBtn:disabled::before
{
	background-color: #cccccc;
}

.loginLogo
{
	padding: 0 15px;
	margin-bottom: 30px;
}

.box_UserNoRole
{
	padding:10px;
	min-height:5vh;
	background-color:#BFBFBF;
}

.resetPwdTitle
{
	font-size:26px;
	font-weight:600;
	color:#50636d
}

/*Pop up*/
#twoFactorPopup
{
	min-height: 400px;
	width: 50%;
	background-color: #f7f7f7;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 25%;
	z-index: 5;
	right: 25%;
}

/*login verifycode input*/
#twoFactorPopup
{
	height: 295px;
}

.twoFactorHeader
{
	margin-top: 60px;
}

#GetTwoFactorAuthCode_Code
{
	display: inline-flex;
	margin-right: 10%;
	margin-bottom: 2%;
}

.verifyCode
{
	width: 70px;
	height: 70px;
	margin-left: 5%;
	font-size: 30px !important;
	text-align: center;
}

#GetTwoFactorAuthCode_Code input[type="number"]:focus
{
	box-shadow: 0 0 5px #337ab7 !important;
	padding: 3%;
	font-size: 30px;
	border: 1px solid #337ab7;
}

.verifyCode:first-child
{
	margin-left: 0;
}

#GetTwoFactorAuthCode_Code input::-webkit-outer-spin-button,
#GetTwoFactorAuthCode_Code input::-webkit-inner-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}

.twoFactorTimer
{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5%;
}

.closeIcon
{
	position: absolute;
	top: 1%;
	right: 1%;
	font-size: 32px;
	cursor: pointer;
}

.closeIcon:hover
{
	font-size: 34px;
}

.otpBg
{
	background: rgba(0,0,0,.4);
	cursor: pointer;
	height: 100%;
	position: fixed;
	text-align: center;
	top: 0;
	right: 0;
	width: 100%;
	z-index: 10000;
}

.alertHistorySearchBg
{
	border: 1px solid rgb(51, 72, 88);
	min-width: 70%;
	margin: 0 auto;
	padding-top: 15px;
	background-color: #f3f3f3;
}

/* Power outage box */
.infobox-container
{
	text-align: center;
	font-size: 0;
}

.circleDiv
{
	background: #fff;
	text-align: center;
	vertical-align: middle;
}

.circleDiv span
{
	display: block;
	padding: 5px;
	font-size: 18px;
	color: #555;
	font-weight: bold;
}
.infobox-content
{
	padding:none !important;
	font-size: 15px;
	text-align: center !important;
}
.infobox
{
	text-align:center;
	max-width: 150px;
	min-height: 100px;
	cursor:pointer;
}
.infobox:active , .infobox:hover ,.infobox:focus
{
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	z-index: 100;
}

.infobox.active
{
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	z-index: 1;
}

.bg-dark
{
	background-color: #6c757d !important;
}

.infoTable
{
	width:100%;
	text-align:center;
	border:1px solid #555;
}

.infoTable tr td
{
	border:1px solid #555;
}

.infoTable thead
{
	font-weight:bold;
	background-color:#d5e0e3;
}

.notifyUser
{
	background-color:#f7e4c1;
	margin-right:5px;
	padding:3px;
	border-radius: 5px;
}

/* Network */
.custom_Scrollbar
{
	height:60vh;
	overflow:auto;
	margin-top:2%
}

.custom_Scrollbar::-webkit-scrollbar
{
	width: 10px;
}

.custom_Scrollbar::-webkit-scrollbar-thumb
{
	background: #666;
	border-radius: 20px;
}

.listView
{
	padding: 1%;
	font-weight:bold;
	text-align:left;
}

#list
{
	list-style-type: none;
}
.list_Child
{
	border-left: 5px solid #5D676D;
	padding: 2% 3%;
	background-color: #D3E0E9;
	margin-bottom: 2%;
	transition-duration: 0.3s;
	transition-property: transform;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	cursor:pointer;
}
.list_Child:hover
{
	border-left: 5px solid #950739;
	padding: 2% 3%;
	background-color: #D3E0E9;
	margin-bottom: 2%;
	font-weight:bold;
}
.list_Child.active
{
	border-left: 5px solid #950739;
	padding: 2% 3%;
	background-color: #D3E0E9;
	margin-bottom: 2%;
	font-weight:bold;
}

.initPageStyle
{
	border-bottom: 1px solid;
	padding: 15px 0;
}

.statusBlock
{
	font-weight: bold;
	text-align: left;
	font-size: 24px;
}

.confirmCount
{
	color:#009875;
}

.uncomfirmCount
{
	color:#a53629;
}

.count
{
	font-size: 1.5rem;
	font-weight: bold;
	text-align: right;
}

.departmantNo
{
	border-bottom: 1px solid #555;
	margin: 0 20px;
	font-size: 24px;
}

.nameBlock
{
	border:2px solid #555;
	height:65vh;
	padding: 5px;
	font-weight: bold;
	overflow: auto;
}

.employeeBlock
{
	border: 1px solid;
	padding: 10px;
	display: inline-block;
	margin-left: 5px;
	cursor: pointer;
	margin-bottom: 5px;
}

.guestBlock
{
	cursor:default !important;
}

.dashboardTitle
{
	font-size: 12rem;
	position:absolute;
	top:50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -265px;
	font-weight: bold;
	color:#e2e2e2;
}

.infoSpace
{
	margin-bottom: 5px;
}

.btnStyle
{
	margin-right: 5px;margin-bottom:5px;
}

.loadingGIF
{
	position: absolute;
	left: calc(45% - 10px);
	top: calc(50% - 10px);
	z-index: 9999;
	width: 10%;
}

.loadingGIF_init
{
	position: absolute;
	left: calc(45% - 10px);
	top: calc(50% - 10px);
	z-index: 9999;
	width: 10%;
}

.loadingGIF_calendar
{
	position: absolute;
	left: calc(45% - 10px);
	top: calc(50% - 10px);
	z-index: 9999;
	width: 20%;
}

.searchInput
{
	width: 50%;
	display: block;
	margin: 0 auto;
	border: 1px solid rgb(51, 72, 88);
	padding: 10px;
	background-color: #f3f3f3;
}

/* phone */
@media screen and (max-width: 480px) and (min-width: 320px)
{
	#burger
	{
		padding: 5px;
	}

	.employeeBlock
	{
		padding: 5px;
	}

	.dashboardTitle
	{
		font-size: 6rem;
		left: 50%;
		margin-top: -50px;
		margin-left: -127px;
	}
	.header .pageTitle
	{
		display: none;
	}

	.snotify
	{
		width: 300px !important;
	}

	.snotifyToast__body
	{
		font-size: 12px !important;
	}

	.snotifyToast__title
	{
		font-size: 1em !important;
	}

	.loadingGIF
	{
		left: calc(33% - 10px);
		top: 100%;
		width: 40%;
	}
	.searchInput
	{
		width: 100%;
	}
}

/* laptop */
@media screen and (max-width: 1440px) and (min-width: 1200px)
{
	#burger
	{
		padding: 5px;
	}
}

/* ipad */
@media screen and (max-width: 1024px) and (min-width: 768px)
{
	.loadingGIF
	{
		left: calc(38% - 10px);
		top: 66%;
		width: 30%;
	}
}

.table_List_head
{
	border-bottom: none !important;
	color:#808080;
	font-size: 0.75rem;
	padding: 0 1rem;
	margin-bottom: 0.5rem;
}

.table_List_border
{
	border-left: none;
	border-right: none;
	border-bottom: 5px solid #E9E9E9 !important;
}

.table_List_Link
{
	color:#0058A9;
	font-weight:bold;
}

#inputFile,
#singleFile,
#imageContainer
{
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.blockTitle
{
	font-size: 16px;
	color: #333333;
	border-bottom: 1px solid #0F5BA2;
	font-weight: bold;
}

.nav-link
{
	font-size: 16px;
	color: #333333;
}

.documentTitle
{
	color:#0058A9;
	font-size:45px;
	word-break: break-all;
}

.font_align
{
	align-items: center;
}

@keyframes fadeInOpacity
{
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media (max-width: 767px)
{
	.content-to-hide
	{
		display: none;
	}

	.mobile_fontsize
	{
		font-size: 12px;
	}

	.space_input
	{
		margin-bottom: 1rem;
	}
}

.clickable_text_align
{
	vertical-align:middle;
}

.document_Display_Info
{
	font-size:14px;
	color:#333333;
}

.document_Display_Content
{
	font-size:16px;
	color:#333333;
}

.preview_Title
{
	color:#0058A9;
	font-size:32px;
}

.nobg
{
	background: none;
}

/* pluging: datatable ============================= */
table.dataTable thead th,
table.dataTable thead td
{
	padding: 10px 18px;
	border-bottom: 1px solid #dee2e6;
}

table.dataTable.no-footer
{
	border-bottom: 2px solid #dee2e6;
}

table.dataTable thead th,
table.dataTable thead td,
.dataTables_wrapper.no-footer .dataTables_scrollBody
{
	border-bottom: 1px solid #B2B2B2 !important;
}

#tableList.dataTable thead th,
#tableList.dataTable thead td,
.dataTables_wrapper.no-footer .dataTables_scrollBody
{
	border-bottom: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover
{
	background: none;
	color: black!important;
	border-radius: 4px;
	border: 1px solid #828282;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active
{
	background: #0058A9;
	color: #333333!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button
{
	background: #ffffff;
	border: 1px solid #ffffff;
	color: #333333!important;
	font-size: 14px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover
{
	color: #ffffff !important;
	border: none;
	background: #0058A9;
}

.dataTables_wrapper .dataTables_info
{
	text-align: left;
	margin-bottom: 1%;
	display: block;
}

.page-content
{
	background-color: #ffffff;
	position: relative;
	margin: 0;
	padding: 8px 20px 24px;
}

.column_minWidth
{
	min-width: 82px;
	word-break: break-word;
	word-wrap: break-word;
}

.column_minWidth-middle
{
	min-width: 200px;
	word-break: break-word;
}

table.table tr th, table.table tr td
{
	vertical-align: middle;
}

table.table tbody tr td.action button
{
	width: 36px;
	height: 36px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* pluging: multiselect ============================= */
.multiselect__tag
{
	background-color: #207dd4;
}

.multiselect__tag-icon:hover
{
	background-color: #0058A9;
}

.multiselect__tag-icon:after
{
	color: #fff;
}

/* main ============================= */
.pageWrap
{
	background: #E9E9E9;
	flex: 1 1 auto;
	overflow-y: auto;
	text-align: left;
	position: relative;
}

@media (max-width: 576px)
{
	.pageWrap
	{
		padding-top: 52px;
		padding-bottom: 1rem;
	}
}

.title_page, /* in addition to the listPage, dispalyPage */
.pageTitle
{
	font-size: 2rem;
	font-weight: bold;
	text-align: left;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.pageSubTitle
{
	font-size: 1.25rem;
	width: fit-content;
	margin-top: 2rem;
	margin-bottom: -1.75rem;
	border-bottom: 1px solid #0058A9;
}

.dayShow_label
{
	height: fit-content;
	font-weight: bold;
	background: #FF8900;
	padding: 0.4rem;
	border-radius: 0.5rem;
	box-shadow: 0px 4px 6px 1px rgba(255, 137, 0, 0.5);
}

.btn > span
{
	margin-left: 0.5rem;
}

.form-control
{
	font-size: 0.875rem;
	border-radius: 0.5rem;
}

.form-control:disabled, .form-control[readonly]
{
	cursor: not-allowed;
}

.form-control::placeholder { color: #ccc; }

.custom-select
{
	border-radius: 0.5rem;
	font-size: 0.875rem;
}

.custom-select:disabled
{
	color: rgba(0, 0, 0, 0.7);
	background-color: #cdd2d6;
}

.pagination
{
	margin: 1.5rem 0;
}

/* backup: Only if the website is normal, this comment can only be deleted
.backToTop
{
	display: inline-block;
	text-align: center;
	border-radius: 4px;
	position: fixed;
	bottom: 75px;
	right: 405px;
	transition: background-color .3s,opacity .5s, visibility .5s;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
}

.backToTop.show
{
	opacity: 1;
	visibility: visible;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}
*/

/* main - drag: only in management page ============================= */
.drag
{
	cursor: grab;
}

.drag .dragIcon
{
	font-size: 1.5rem;
	color: #333;
}

.drag .orderImg
{
	width: 100%;
	height: auto;
}

.drag.li_list
{
	padding: 0.5rem 0;
	align-items: center;
	user-select: none;
}

/* main - list ============================= */
.li_list,
.item
{
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	background-color: #ffffff;
	margin: 0;
	border-radius: 0.875rem;
	margin-bottom: 0.625rem;
	text-align: left;
	overflow: hidden;
}

.mainPage .li_list:hover,
.homePage .li_list:hover,
.listPage .li_list:hover,
.directory .li_list:hover
{
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1),
	inset 0px 0px 0px 2px rgba(15, 91, 161, 1);
}

.li_list .wrap
{
	display: block;
	margin: 0;
	color: #333;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
}

.li_list .title
{
	font-size: 1rem;
	padding: 0;
	margin: 0 0.5rem 0 0;
	line-height: inherit;
	font-weight: bold;
	color: #0058A9;
}

.li_list .textOverflow
{
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	word-break: break-word;
}

@media screen and (max-width: 1200px)
{
	.li_list .date
	{
		font-size: 0.75rem;
		color: #808080;
	}

	.li_list .category
	{
		font-size: 0.875rem;
	}
}

@media screen and (max-width: 992px)
{
	.li_list .wrap
	{
		padding: 0.625rem 1.25rem ;
	}

	.li_list .textOverflow
	{
		-webkit-line-clamp: 2;
	}
}

/* main - search group ============================= */
#searchGroup
{
	align-items: flex-end;
	flex-wrap: nowrap;
	padding: 0 5px;
	margin-bottom: 1rem;
}

#searchGroup .form-group
{
	margin-right: 6px;
	padding: 0;
}

/* for select */
#searchGroup .custom-select
{
	height: 38px;
}

/* for datePicker */
#datePicker
{
	min-width: 13rem;
}

#datePicker span input
{
	font-size: 0.875rem;
	max-width: 13rem;
	height: 38px;
	border-radius: 0.5rem
}

/* for keywords */
#searchGroup .multiselect
{
	margin-bottom: -2px;
}

#searchGroup .multiselect__tags
{
	min-height: initial;
	padding: 0 46px 0 0;
	border: 1px solid #ced4da;
	border-radius: 0.5rem;
	line-height: 38px;
	height: 38px; /* if user need to keyin word, this line need delete */
}

#searchGroup .multiselect__select
{
	height: 38px;
}

#searchGroup .multiselect__tag
{
	margin: 7px 8px 0 8px;
}

#searchGroup .multiselect__input,
#searchGroup .multiselect__placeholder
{
	font-size: 0.875rem;
	padding: 0 0.75rem;
	margin: 0;
	border-radius: 0.5rem;
	line-height: 38px;
	background: transparent;
	white-space: nowrap;
}

#searchGroup .multiselect__option
{
	font-size: 0.875rem;
	white-space: normal;
}

#searchGroup .multiselect__option--highlight
{
	background-color: #207dd4;
}

/* for search button */
#searchGroup .btn-primary
{
	white-space: nowrap;
	margin-bottom: 16px;
	padding: 5px 12px;
}

#searchGroup.validation
{
	align-items: initial;
}

#searchGroup.validation .btn-primary
{
	height: fit-content;
	margin-top: calc(18px + 0.25rem);
}

@media screen and (max-width: 992px)
{
	#searchGroup
	{
		flex-wrap: wrap;
	}

	#searchGroup .form-group
	{
		margin-right: 0;
	}

	/* for datePicker */
	#searchGroup #datePicker,
	#searchGroup #datePicker span input
	{
		width: 100%;
		max-width: none;
	}

	/* for keywords */
	#searchGroup #keyword
	{
		width: 80% !important;
	}

	@media (max-width: 576px)
	{
		#searchGroup .multiselect__tags-wrap
		{
			display: inline-flex;
			width: 100%;
		}

		#searchGroup .multiselect__tag
		{
			margin: 0 0 0 0.5rem;
		}

		#searchGroup .multiselect__placeholder,
		#searchGroup .multiselect__tags
		{
			font-size: 16px !important;
			height: initial;
		}
	}

	/* for search button */
	#searchGroup .btn-primary
	{
		margin-left: 6px;
	}
}

/* main - advanced search ============================= */
#sidebar-advancedSearch #searchGroup .form-group
{
	margin-right: 0;
}

#sidebar-advancedSearch #searchGroup .multiselect__tags
{
	display: block;
	height: initial;
}

#sidebar-advancedSearch #searchGroup .multiselect__tags-wrap
{
	display: inline-grid;
	width: 100%;
}

#sidebar-advancedSearch #searchGroup .multiselect__tag
{
	width: 100%;
	margin: 0 0 0.5rem 8px;
}

/* main - file list ============================= */
.fileList
{
	text-align: left;
	word-break: break-all;
	display: block;
	white-space: normal;
}

.fileName
{
	display: inline-block;
	margin-right: 3px;
}

.fileName span
{
	background-color: #ffffff;
	color: #333333;
	font-weight: normal;
	padding: 5px;
}

.documentDisplayPage .fileList
{
	display: flex;
	flex-wrap: wrap;
	margin: 1rem 0;
}

.documentDisplayPage .fileName,
.documentManagementForm .fileName
{
	display: flex;
	padding: 0.5rem 1rem;
	margin: 0 0.5rem 0.5rem 0;
	background-color: #ffffff;
	border-radius: 0.625rem;
	font-size: 0.875rem;
	color: #333333;
	cursor: pointer;
	word-break: break-all;
}

.documentDisplayPage .fileName svg,
.documentManagementForm .fileName svg
{
	margin-right: 0.5rem;
}

.documentDisplayPage .fileName:hover,
.documentManagementForm .fileName:hover
{
	color: #0058A9;
}

.custom-file-upload
{
	border: 1px solid #0058A9;
	display: inline-block;
	padding: 0.5rem 1rem;
	cursor: pointer;
	color:#0058A9;
	border-radius: 0.5rem;
	font-size: 0.875rem;
}

.custom-file-upload:hover
{
	color: #fff;
	background-color: #0058A9;
}

/* main - hashtag list ============================= */
.documentDisplayPage .hashtagList,
.documentManagementForm .hashtagList
{
	margin-bottom: 2rem;
}

.documentDisplayPage .hashtag,
.documentManagementForm .hashtag
{
	display: inline-block;
	font-size: 0.75rem;
	color: #0058A9;
	padding: 0;
	margin: 0;
	margin-bottom: 0.5rem;
	margin-right: 0.625rem;
	line-height: 1rem;
}

/* main - section ============================= */
.sectionWrap
{
	padding: 0.9375rem 0 0 0;
}

.sectionHeader
{
	text-align: left;
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	font-weight: bold;
	color: #033A93;
	margin-bottom: 0.125rem;
	height: 1.5rem;
}

.sectionHeader .icon
{
	margin-right: 0.625rem;
	font-size: 1rem;
}

.sectionHeader .line
{
	height: 1px;
	background: #033A93;
	flex-grow: 1;
	margin-left: 0.625rem;
}

.sectionWrap #itemList
{
	margin-bottom: 0;
}

/* main - homePage ============================= */
.homePage .pageTitle
{
	margin-top: 0;
	margin-bottom: 0;
}

/* main - documentDisplayPage ============================== */
.documentDisplayPage
{
	position: relative;
	height: 100%;
	color: #333333;
}

.documentDisplayPage .bgColor
{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #efefef;
}

.documentDisplayPage .wrap
{
	position: relative;
	background-color: #efefef;
}

.documentDisplayPage .toolBar
{
	position: sticky;
	left: 0;
	top: 0;
	z-index: 99;
	padding: 1rem 2rem 0 2rem;
	text-align: right;
}

.documentDisplayPage .toolBar .backToTop
{
	position: initial;
	opacity: initial;
	visibility: initial;
	margin-left: 0.5rem;
}

.documentDisplayPage .document
{
	padding-right: 2rem;
	padding-left: 2rem;
}

.documentDisplayPage .category
{
	padding: 0;
	margin: 0;
	margin-bottom: 0.5rem;
	font-size: 0.875rem;
	font-weight: bold;
	border-bottom: 1px solid #0058A9;
	width: fit-content;
}

.documentDisplayPage .title
{
	padding: 0;
	margin: 0;
	margin-bottom: 2rem;
	font-size: 2.5rem;
	font-weight: bold;
	line-height: 3rem;
	color: #0058A9;
	word-break: break-word;
}

.documentDisplayPage .info
{
	font-size: 0.875rem;
	margin: 0;
	padding: 0;
}

.documentDisplayPage .content
{
	margin-top: 2rem;
	margin-bottom: 2rem;
	font-size: 1rem;
	color: #333333;
	word-break: break-word;
}

.documentDisplayPage .declaration
{
	font-size: 0.75rem;
	text-align: center;
	padding: 2rem 0;
	border-top: 1px solid rgb(178, 178, 178);
}

@media screen and (max-width: 992px)
{
	.documentDisplayPage .toolBar,
	.documentDisplayPage .document
	{
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.documentDisplayPage .fileList
	{
		display: block;
	}

	.documentDisplayPage .fileName
	{
		margin: 0 0 0.5rem 0;
	}
}

@media screen and (max-width: 576px)
{
	.documentDisplayPage .toolBar
	{
		position: fixed;
		top: 52px;
	}

	.documentDisplayPage .document
	{
		padding-top: 4rem;
	}
}

/* main - tablePage ============================= */
.tablePage
{
	width: 80vw;
}

.tablePage #searchGroup
{
	margin-bottom: 0;
}

.tablePage #searchGroup .form-control
{
	height: 38px;
}

.tablePage .dataTables_wrapper
{
	font-size: 1rem;
	margin-bottom: 2rem;
}

.tablePage .table th,
.tablePage .table td
{
	border-left: 1px solid #B2B2B2;
	border-right: 1px solid #B2B2B2;
	border-top: 1px solid #B2B2B2;
}

.tablePage .table th label,
.tablePage .table td label
{
	margin-bottom: 0;
}

.tablePage .table thead tr th
{
	padding: 0.75rem 1.25rem 0.75rem 0.75rem;
	margin: 0;
	color: #fff;
	background-color: #1f5080;
	white-space: nowrap;
}

.tablePage .table thead tr th:first-child
{
	padding: 0.75rem;
}

.tablePage .table tbody tr td a
{
	color: #0058A9;
	font-weight: bold;
}

.tablePage .table thead tr .photo
{
	width: 256px !important;
}

.tablePage .table thead tr .title
{
	min-width: 10vw;
}

#previewImg
{
	width: 320px;
	height: 180px;
}

@media screen and (max-width: 992px)
{
	.tablePage
	{
		width: initial;
	}
}

/* main - form for document add, update ============================= */
.documentManagementForm
{}

.documentManagementForm .text-danger
{
	font-size: 0.875rem;
}

.documentManagementForm .fileList
{
	margin: 0;
}

/* scrollBar height ============================= */
.carousel_scrollBar
{
	height: calc(100vh - 52px - 15px - 10px - 43px);
	overflow-y: auto;
	overflow-x: hidden;
}

.annoucement_scrollBar
{
	height: calc(100vh - 52px - 15px - 24px - 35px - 10px - 10px - 43px);
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 0.625rem;
	text-align: left;
}

.toolLink_scrollBar
{
	height: calc(50vh - 52px - 35px);
	overflow-y: auto;
	overflow-x: hidden;
}

.calendar_scrollBar
{
	overflow-y: auto;
	max-height: calc(50vh - 52px - 35px);
	white-space: pre-line;
}

/* carousel (image, video) ============================= */
.carousel
{
	border-radius: 1.25rem;
	overflow: hidden;
	margin-bottom: 1rem;
	text-shadow: 1px 1px 2px #333;
}

.carousel-indicators
{
	margin-bottom: 0.625rem;
}

#image-home-page .image-plus-size
{
	width: 2rem;
	height: 2rem;
	padding: 0.375rem;
	position: absolute;
	bottom: 1rem;
	right: calc(7.5% - 1rem);
	cursor: pointer;
	color: #fff;
	z-index: 999;
	opacity:0.5;
}

#video .carousel-caption
{
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
}

#video h3
{
	padding: 0 1.25rem;
	margin: 0;
	width: 100%;
	height: fit-content;
	font-size: 1.25rem;
	margin-bottom: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	word-break: break-word;
	white-space: break-spaces;
}

#video p
{
	padding: 0;
	margin: 0;
}

#video .videoPlayIcon
{
	display: block;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	box-shadow:inset 0 0 0 4px rgba(255, 255, 255, 1);
	padding: 20px 18px 20px 22px;
	background: rgba(255, 255, 255, 0.4);
	font-size: 1.5rem;
	position: absolute;
	top: calc(50% - 32px);
	left: calc(50% - 32px);
	cursor: pointer;
}

#video .videoCoverImg
{
	opacity: 0.5;
}

#video .fa-play, #image-home-page .fa-search-plus
{
	transition: transform .5s ease-in-out;
}

#video .fa-play:hover, #image-home-page .fa-search-plus:hover
{
	transform: scale(1.25);
	opacity: 1;
}

@media screen and (max-width: 992px)
{
	.carousel_scrollBar
	{
		height: auto;
		overflow: inherit;
	}
}

/* annoucement ============================= */
.annoucement_wrap.li_list
{
	padding: 0 1.25rem;
	margin-right: 0.625rem;
}

.annoucement_item
{
	flex-grow: 1;
	margin-right: 0.9375rem;
	cursor: pointer;
}

.annoucement_item .annoucement_category
{
	font-size: 0.875rem;
	font-weight: normal;
	color: #333;
	padding: 0;
	margin: 0;
}

.annoucement_item .annoucement_title
{
	font-size: 1rem;
	font-weight: bold;
	color: #0058A9;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	word-break: break-word;
}

.annoucement_date
{
	font-size: 0.75rem;
	color: #808080;
	padding: 0;
	margin: 0;
}

.annoucement_scrollBar .btn-primary
{
	width: calc(100% - 0.625rem);
	border-radius: 0.625rem;
}

.annoucement_scrollBar .btn-primary .icon
{
	margin-right: 16px;
}

@media screen and (max-width: 992px)
{
	.annoucement_scrollBar
	{
		height: auto;
		overflow: inherit;
	}

	.annoucement_wrap.li_list
	{
		margin-right: 0;
	}

	.annoucement_scrollBar .li_list .wrap
	{
		padding-right: 0;
		padding-left: 0;
	}

	.annoucement_scrollBar .btn-primary
	{
		width: 100%;
	}
}

/* week calendar ============================= */
#fullcalendar
{
	height: calc(50vh - 100px) !important;
}

.fc-col-header-cell-cushion,
.fc-timegrid-slot-label-cushion,
.fc-timegrid-axis-cushion
{
	color:#808080 !important;
	font-size: 12px !important;
}

.fc-timeGridWeek-view
{
	background-color: white;
}

.fc-right .fc-prev-button, .fc-right .fc-next-button
{
	background-color: #0F5BA2;
	background-image: none;
}

.fc-view-harness
{
	border-radius: 0.625rem;
	overflow: hidden;
}

.fc-theme-standard .fc-scrollgrid
{
	border: none;
}

#fullcalendar .fc-toolbar-chunk:nth-child(1)
{
	border-right: 1px solid #033A39;
}

#fullcalendar .fc-toolbar-chunk:nth-child(3)
{
	border-left: 1px solid #033A39;
}

.fc .fc-button
{
	font-size: 14px;
}

.fc-toolbar-chunk button
{
	width: 2.25rem;
	height: 2.25rem;
}

.fc-toolbar-chunk:nth-child(1) button
{
	border-radius: 8px 0 0 8px;
}

.fc-toolbar-chunk:nth-child(3) button
{
	border-radius: 0 8px 8px 0;
}

.fc-toolbar-chunk:nth-child(2)
{
	flex-grow: 1;
}

.fc-toolbar-chunk:nth-child(2) button
{
	border-radius: 0;
	width: 100%;
}

#fullcalendar .fc-day-today .fc-timegrid-now-indicator-container
{
	background: #EFF6FF;
}

.fc-event-title
{
	white-space: pre-line!important;
}

.fc-event-main
{
	overflow: hidden;
	white-space: pre-line!important;
}

@media screen and (max-width: 992px)
{
	#fullcalendar
	{
		height: 70vh !important;
	}
}

/* toolLink ============================= */
.toolLink_wrap
{
	margin-top: 1rem;
}

.toolLink_wrap #itemList
{
	display: flex;
}

.toolLink_wrap .linkCol
{
	flex-grow: 1;
	display: inline-block;
	flex-direction: column;
	width: 50%;
}

.toolLink_wrap .link-container
{
	padding: 2px 10px;
	margin: 0px 15px 10px 0px;
	border-radius: 0.625rem;
	text-align: left;
}

.toolLink_wrap .toolLink
{
	display: flex;
	align-items: top;
	padding: 5px 10px;
	background: #FFFFFF;
	font-size: 1rem;
}

.toolLink_wrap .toolLink .toolLink_icon
{
	display: block;
	width: 1rem;
	font-size: 1rem;
	margin-top: 3px;
	margin-right: 0.625rem;
	color: #808080;
}

.toolLink_wrap .toolLink .toolLink_title
{
	font-size: 0.875rem;
	font-weight: bold;
	color: #0058A9;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

@media screen and (max-width: 992px)
{
	.toolLink_scrollBar
	{
		height: auto;
		overflow: inherit;
	}

	.toolLink_scrollBar #itemList
	{
		margin-bottom: 0;
	}
}

@media screen and (max-width: 576px)
{
	.toolLink_scrollBar #itemList
	{
		flex-direction: column;
	}

	.toolLink_scrollBar .linkCol
	{
		width: 100%;
	}

	.toolLink_scrollBar .link-container
	{
		margin: 0px 0px 10px 0px;
	}
}

/* Club ============================= */
.container_clubInfo
{
	width: 15rem;
	min-height:24rem;
}

.card_body
{
	width: 15rem;
	min-height:22rem;
}

.container_club_imgs
{
	height: 80vh;
	width: 90vmin;
}

.club_imgbg
{
	background-color: grey;
}

@media (max-width: 768px)
{
	.container_club_imgs
	{
		height: 100vmin;
		width: 100vw;
	}
}

.container_clubDisplayInfo
{
	height: 80vh;
	padding: 2vh 2rem;
	overflow-y: auto;
}

.container_clubDisplayInfo .title
{
	margin: 0;
	margin-bottom: 1rem;
	font-weight: bold;
	word-wrap: break-word;
}

.container_clubDisplayInfo .btn
{
	width: 100%;
	margin-bottom: 1rem;
}

.container_clubDisplayInfo .subtitle
{
	margin-bottom: 0.25rem;
	margin-top: 2rem;
	font-size: 0.75rem;
	color: #999;
}

.img_club_container
{
	min-height: 238px;
	width: 100%;
}

@media (max-width: 992px)
{
	.container_clubDisplayInfo
	{
		height: auto;
		padding: 2rem 2rem 8rem 2rem;
	}
}

.club_title
{
	font-size: 18px;
}

.club_time
{
	font-size: 14px;
}

@media (max-width: 767px)
{
	.card_body
	{
		width: 100%;
	}
}

/* About Committee ============================= */
.committee .nav-pills .nav-link.active,
.committee .nav-pills .show>.nav-link
{
	color: #f3f3f3;
	font-weight: bold ;
	background-color: #0F5BA2;
}

/* Meeting room booking ============================= */
.meetingRoomPage #searchGroup
{
	margin-bottom: 1rem;
}

.meetingRoomPage #searchGroup .multiselect
{
	max-width: initial;
}

.meetingRoomPage #searchGroup .multiselect__tags
{
	display: flex;
}

.meetingRoomPage #searchGroup .multiselect__tags-wrap
{
	display: flex;
	max-width: 80%;
}

.meetingRoomPage #searchGroup .multiselect__tag
{
	height: fit-content;
	margin-right: 0;
	margin-bottom: 0.5rem;
	padding: 4px 20px 4px 8px;
}

.meetingRoomPage #searchGroup .multiselect__strong
{
	line-height: inherit;
	margin-left: 0.5rem;
	white-space: nowrap;
}

.roomList
{
	height: calc(100vh - 52px - 96px - 75px - 38px - 16px - 0.5rem - 25px - 43px);
	overflow: auto;
}

.roomCalendar
{
	height: calc(100vh - 52px - 96px - 75px - 0.5rem - 25px - 43px);
	overflow: auto;
}

.roomList p
{
	margin: 0;
	padding: 0;
	font-size: 0.75rem;
}

.roomList .room_item
{
	cursor: pointer;
	margin-top: 0.375rem;
	margin-left: 0.375rem;
	margin-right: 0.75rem;
	margin-bottom: 1rem;
}

.roomList .room_item:hover,
.roomList .room_item.active
{
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1),
	0px 0px 0px 4px rgba(0, 88, 169, 1);
	margin-bottom: 2rem;
}

.room_item .cover
{
	width: 100%;
	height: 140px;
}

.room_item .cover img
{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}

.room_item .infoWrap
{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem;
	background: rgba(50, 50, 50, 0.5);
	background: rgba(0, 0, 0, 0.6);
	color: #fff;
}

.room_item .infoWrap .title
{
	color: #fff;
}

.room_item .infoWrap .moreInfoBtn
{
	height: fit-content;
}

.room_item.active .bookingBtn
{
	display: block;
}

.room_item .bookingBtn
{
	width: 100%;
	display: none;
	border-radius: initial;
	margin-top: 1px;
}

#modal-roomDetail .detailItem
{
	border-top: 1px solid #ccc;
	margin-top: 1rem;
	padding-top: 1rem;
	word-break: break-word;
}

#modal-roomDetail .detailItem .subtitle
{
	font-size: 0.75rem;
	color: #999;
	margin-bottom: 0.25rem;
}

@media screen and (max-width: 992px)
{
	.meetingRoomPage  #searchGroup .multiselect__tags
	{
		display: block;
		height: initial;
	}

	.meetingRoomPage #searchGroup .multiselect__tags-wrap
	{
		display: inline-grid;
		max-width: 100%;
	}
}

@media screen and (max-width: 768px)
{
	.roomList
	{
		display: flex;
		width: fit-content;
		height: fit-content;
		margin-top: 1rem;
		padding-bottom: 6px !important;
	}

	.roomList .room_item
	{
		width: 490px;
		height: fit-content;
	}

	.roomList .room_item:hover,
	.roomList .room_item.active
	{
		margin-bottom: 0;
	}

	.roomCalendar
	{
		height: 50vh;
	}
}

@media screen and (max-width: 576px)
{
	.room_item .infoWrap .textOverflow
	{
		-webkit-line-clamp: 1;
		word-break: break-word;
	}

	.roomList .room_item
	{
		width: calc(100vw - 15vw);
	}
}

/* Meeting room report ============================= */
.meetingRoomReport
{
	width: 50%;
}

.meetingRoomReport .groupBorder-bottom
{
	border-bottom: 1px solid #ccc;
}

@media (max-width: 1200px)
{
	.meetingRoomReport
	{
		width: 100%;
	}
}

/* Personal booking ============================= */
.personalBookingRoom
{
	color: #333;
}

.personalBookingRoom p
{
	margin: 0;
}

.personalBookingRoom .pageTitle
{
	margin-bottom: -2rem;
}

.personalBookingRoom .card-header
{
	padding-top: 0.25rem;
	padding-bottom: 0;
	background: #f8f9fa;
}

.personalBookingRoom .li_list .btn-light
{
	width: 40px;
	height: 40px;
}

.personalBookingRoom .noBook
{
	font-size: 1rem;
	color: gray;
	margin-top: 3rem;
	margin-bottom: 0.5rem;
	position: relative;
}

.personalBookingRoom .year
{
	font-size: 1rem;
	color: gray;
	margin-top: 3rem;
	margin-bottom: 0.5rem;
	position: relative;
}

.personalBookingRoom .year::after
{
	content: "";
	width: calc(100% - 3rem);
	height: 1px;
	background-color: grey;
	position: absolute;
	top: 50%;
	right: 0;
}

.personalBookingRoom .li_list .date
{
	font-size: 40px;
	font-weight: bold;
	color: #0058A9;
	line-height: 1;
	margin-bottom: 0.375rem;
}

.personalBookingRoom .li_list .time
{
	font-size: 1.125rem;
	margin-bottom: 1rem;
}

.personalBookingRoom .li_list .location
{
	font-size: 0.875rem;
	border-bottom: 1px solid #0058A9;
	margin-bottom: 0.25rem;
	width: fit-content;
}

.personalBookingRoom .li_list .roomName
{
	font-weight: bold;
	color: #0058A9;
	padding: initial;
	margin: initial;
	word-break: break-word;
}

.personalBookingRoom .li_list .secondInfo
{
	font-size: 0.875rem;
	color: gray;
}

.personalBookingRoom .li_list .purpose
{
	-webkit-line-clamp: 2;
	word-break: break-word;
}

@media (max-width: 576px)
{
	.personalBookingRoom .card-header
	{
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.personalBookingRoom .li_list .date
	{
		font-size: 1.625rem;
	}

	.personalBookingRoom .li_list .time
	{
		font-size: 1rem;
	}

	.personalBookingRoom .li_list .textOverflow
	{
		-webkit-line-clamp: initial;
	}
}

/* Personal booking ============================= */
.meetingRoomCheckInOut
{
	color: #333;
	min-height: 70vh;
}

.meetingRoomCheckInOut .horizontal-line
{
	flex-grow: 1;
	margin-left: 0.5rem;
	border-bottom: 1px solid gray;
}

/* suggestionBoxPage / employeeAppealPage ============================= */
.suggestionBoxPage .fileList,
.employeeAppealPage .fileList
{
	margin-top: 0;
}

.suggestionBoxPage .fileName,
.employeeAppealPage .fileName
{
	border: 1px solid #ccc;
}

/* Directory ============================= */
.directory
{
	color: #333;
}

.directory #searchGroup
{
	align-items: initial;
}

.directory #searchGroup .form-control
{
	height: 38px;
}

.directory #searchGroup .btn-primary
{
	height: fit-content;
	margin-top: calc(18px + 0.25rem);
}

.directory .li_list > .row
{
	align-items: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.directory .li_list .action > a:hover svg
{
	color: #0058A9 !important;
}


@media (max-width: 992px)
{
	.directory .li_list > .row
	{
		align-items: flex-start;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.directory .row > .row
	{
		margin-right: 0;
	}

	.directory .photo
	{
		text-align: center;
	}

	.directory .action
	{
		text-align: right;
		margin-top: 0.5rem;
		margin-right: 0.5rem;
	}
}

.modify-record-table
{
	border: 1px solid #000000;
	background-color: #000000;
}
.modify-record-table .modify-record-table-row
{
	display: grid;
	grid-gap: 1px;
	grid-template-columns: 1.5fr 1.5fr 4fr;
}
.modify-record-table .modify-record-table-row:not(:last-child)
{
	border-bottom: 1px solid #000000;
}
.modify-record-table .modify-record-table-row > div
{
	padding: 8px;
	word-break: break-word;
	background-color: #fff;
}
.modify-record-table .modify-record-table-header
{
	color: #ffffff;
	background-color: #ffffff;
}
.modify-record-table .modify-record-table-header > div
{
	background-color: #1f5080;
}
.modify-record-table .modify-record-table-row h5
{
	font-size: 16px;
	margin-top: 12px;
    margin-bottom: 0;
}
.modify-record-table .modify-record-table-row h6
{
	color: #6c757d;
	margin: 0;
	font-size: 12px;
}
.modify-record-table .modify-record-table-row article:not(:last-child)
{
	margin-bottom: 10px;
	border-bottom: 1px solid #ddd;
}
.modify-record-table .modify-record-table-row .update-item
{
	display: grid;
	margin-bottom: 10px;
	grid-template-columns: 3fr 1fr 3fr;
}
.modify-record-table .modify-record-table-row .update-item .icon-arrow
{
	display: flex;
	align-items: center;
	justify-content: center;
}

.autocomplete
{
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
	font-size: 0.875rem;
    border-radius: 0.5rem;
	width: 100%;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}