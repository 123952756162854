%flex-center
{
    display: flex;
    align-items: center;
    justify-content: center;
}

%ellipsis
{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

%candidate-bg
{
    background-size: cover;
    background-image: url('~@/assets/candidate_login_background.png');
    background-repeat: no-repeat;
    background-position: center;
}

// 以下樣式限 PI management 使用 =============================
$pi-color-navbar: #538ebd;
$pi-color-light-gray: #ececec;
$candidate-color-bar: #f5f5f5;
$candidate-color-primary: #0060A9;
$candidate-color-primary-lighten: lighten($candidate-color-primary, 10%);
$candidate-color-primary-disabled: #80AFD4;
$candidate-color-secondary: #6C757D;
$candidate-color-secondary-darken: darken($candidate-color-secondary, 10%);
$candidate-color-error: #FF3D00;
$candidate-color-error-darken: darken($candidate-color-error, 10%);;
$candidate-color-error-background: #FFE7DF;
$candidate-color-text: #333;
$candidate-color-placeholder: #ccc;
$candidate-color-question-background: #F3F4F5;
$candidate-color-question-background-darken: darken($candidate-color-question-background, 5%);
$candidate-color-attachment: #C6DCF6;
$candidate-color-attachment-darken: #90C0F8;

// candidate-resume =============================
%resume-input-basic-style
{
    padding: 0.75rem 0.875rem;
    background-color: #fff;
    border-radius: 0.375rem;
    transition: all 0.2s ease-in-out;
    font-size: 1rem;
    &::placeholder
    {
        color: $candidate-color-placeholder;
    }
    &:focus-within
    {
        box-shadow: inset 0px 0px 0px 1px $candidate-color-primary,
        0px 0px 0px 1px $candidate-color-primary;
    }
}

%resume-error-message
{
    color: $candidate-color-error;
    font-size: 0.75rem;
    display: inline-block;
}

%bg-color-transition { transition: background-color 0.2s ease-in-out; } // for hover button, focus-within item ...

%resume-button
{
    @extend %bg-color-transition;

    display: block;
    padding: 0.5rem 0.75rem;
    color: #fff;
    border-radius: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    user-select: none;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus
    {
        color: #fff;
    }

    svg // font awesome icon
    {
        margin-right: 0.5rem;
        margin-left: 0.25rem;
    }
}

%resume-primary-button
{
    background-color: $candidate-color-primary;
    &:hover, &:active, &:focus { background-color: $candidate-color-primary-lighten;}
    &:disabled { background-color: $candidate-color-primary-disabled; }
}

%resume-secondary-button
{
    background-color: $candidate-color-secondary;
    &:hover, &:active, &:focus { background-color: $candidate-color-secondary-darken; }
}

%resume-danger-button
{
    background-color: $candidate-color-error;
    &:hover, &:active, &:focus { background-color: $candidate-color-error-darken; }
}

%resume-modal-shadow
{
    box-shadow: 0px 10px 50px 50px rgba(0, 0, 0, 0.25);
}
