@import "~@/css/variables.scss";
.calendar-popover
{
	font-size: 12px;
	white-space: pre-line;
	.popover-header
	{
		font-size: 14px;
	}
	.popover-body
	{
		max-height: 400px;
		overflow-y: auto;
	}
}

@media screen and (max-width: 768px)
{
	.calendar-popover .popover-body
	{
		max-height: 200px;
	}
}

.candidate-input-wrapper
{
    border: 2px solid #fff;
    display: flow-root; // clearfix hack
    padding: 1.875rem;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 5px 10px rgba(121, 118, 113, 0.3);
    border-radius: 20px;
    background-color: #f0f0f0;
    &:focus-within { box-shadow: 0 50px 50px rgba(121, 118, 113, 0.3); }

    button.btn
    {
        color: #fff;
        float: right;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        transition: all 0.2s ease-in-out;
        user-select: none;
        line-height: 1.5rem;
        border-radius: 6px;
        background-color: $candidate-color-primary;
        &:focus, &:hover, &:active { background-color: $candidate-color-primary-lighten; }

        svg
        {
            margin-left: 0.25rem;
            margin-right: 0.5rem;
        }
    }

    .error-text
    {
        color: #FF3D00;
        font-size: 0.75rem;
        margin-top: 0.25rem;
        margin-right: 0.5rem;
        &:last-child { margin-right: 0; }
    }
}